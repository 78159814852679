<template>
  <div >
    <b-navbar toggleable="lg" class="navbar is_sticky mb-0 mb-1" id="custom_navbar">
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-nav-text id="navbar_text" style="color: rgba(230,247,167);text-align: center">AMTOIL HIGH SCHOOL</b-nav-text>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="nav-fill w-100">
          <b-nav-item>
            <router-link :to="{name:'home'}"><strong>Home</strong></router-link>
          </b-nav-item>
          <b-nav-item-dropdown v-for="(menu,key) in menus" :key="key">
            <template #button-content>
              <strong>{{ menu.name }}</strong>
            </template>
            <b-dropdown-item v-for="(sub_menu,key2) in menu.public_sub_menus" :key="key2" href="#" style="text-align: left;" >
              <router-link :to="{name:'common.page',params:{menu:menu.name,slug:sub_menu.slug}}" >
                <strong>{{ sub_menu.name }}</strong>
              </router-link>
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item>
            <router-link :to="{name:'faculty'}"><strong>Faculty</strong></router-link>
          </b-nav-item>
          <!-- <b-nav-item>
            <router-link :to="{name:'apply-online'}"><strong>Admission</strong></router-link>
          </b-nav-item> -->
          <b-nav-item>
            <router-link :to="{name:'contact-us'}"><strong>Contact Us</strong></router-link>
          </b-nav-item>
          <b-nav-item id="navbar_text">
            <a :href="backendUrl()">Login</a>
          </b-nav-item>
        </b-navbar-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto" id="navbar_text">
          <b-nav-form>
            <b-form-input size="sm" class="mr-sm-2" placeholder="Search"></b-form-input>
            <b-button size="sm" class="my-2 my-sm-0" type="submit">
              <b-icon icon="search"></b-icon>
            </b-button>
          </b-nav-form>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>
<script>
import {
  BNavbar,BCollapse,BNavbarNav,BNavItemDropdown,BDropdownItem,BNavItem,
    BNavbarToggle,BNavForm,BFormInput,BButton,BCard,BNavText,InputGroupPlugin
} from 'bootstrap-vue'
import Vue from 'vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
Vue.use(InputGroupPlugin)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
export default {
  name:'header',
  components:{
    BNavbar,BCollapse,BNavbarNav,BNavItemDropdown,BDropdownItem,BNavItem,
    BNavbarToggle,BNavForm,BFormInput,BButton,BNavText
  },
  data() {
    return {
      isSticky: false,
      stickyClass: 'is_sticky'
    }
  },
  methods: {
    handleScroll () {

    },
    backendUrl(){
      //window.location.href=process.env.VUE_APP_ENV_BACKENDURL;
      return process.env.VUE_APP_ENV_BACKENDURL;
    }
  },
  mounted () {
    this.handleScroll();
    if(this.menus.length <1) this.$store.dispatch('GET_ALL_MENU');
  },
  computed:{
    menus() {
      return this.$store.getters.menusTop;
    },
  }

}
</script>
<style scoped>
/*.navbar.is_sticky {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  width: 100%;
  -webkit-animation: .95s ease-in-out 0s normal none 1 running fadeInDown;
  animation: .95s ease-in-out 0s normal none 1 running fadeInDown;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
}*/
#custom_navbar {
  background: #2E8B57;
  color: #f4ff97 !important;
}
#custom_navbar ul li a{
   color: #f4ff97 !important;
 }
#custom_navbar ul li{
  /* basic */
  background-color: #2E8B57;
  margin: 0 auto;
  padding: 0px;
  text-align: center;
  /* border-radius */
  border-radius: 0px;
  border-collapse: collapse;
}
#custom_navbar{
  /* basic */
  background-color: #2E8B57;
  margin: 0 auto;
  padding: 0px;
  text-align: center;
  /* border-radius */
  border-radius: 0px;
  /* box-shadow */
  box-shadow: rgba(145, 166, 69) 0 0 0 1px;
  border-collapse: collapse;
}
#custom_navbar .dropdown-item{
  background: #2E8B57;;
}
#custom_navbar .dropdown-item:hover, .dropdown-item:focus{
  background-color: #2E8B57;;
}

@media screen and (max-width: 600px) {
  #head_section {
    visibility: hidden;
    clear: both;
    float: left;
    display: none;
  }
}
@media screen and (min-width: 780px) {
  #navbar_text {
    visibility: hidden;
    clear: both;
    float: left;
    display: none;
  }
  #custom_navbar ul li{
    /* basic */
    background-color: #2E8B57;
    margin: 0 auto;
    padding: 2px;
    text-align: center;
    /* border-radius */
    border-radius: 2px;
    /* box-shadow */
    box-shadow: rgba(145, 166, 69) 1px 0 1px 2px;
    border-collapse: collapse;
  }

}
</style>
<style scoped>
#header_title_front {
  font-family: Bodoni, sans-serif;
}
.dropdown-menu {
  background-color: #2E8B57;
}
#searchbar_background{
  background: rgba(103,90,67);
  border: none;
  margin-right: 2px;
}
#searchbar_background:focus{
  background: rgba(103,90,67);
  border: none;
  color: black;
  margin-right: 2px;
}
#login_background{
  cursor: pointer;
  background: rgba(114,98,65);
  color: rgba(73,60,56);
  padding: 2px 4px 2px 4px;
  border: 1px solid black;
}
#login_background:active{
  background: rgba(114,98,65);
  color: rgba(73,60,56);
  padding: 2px 4px 2px 4px;
  border: 1px solid black;
}
#login_background:focus{
  background: rgba(114,98,65);
  color: rgba(73,60,56);
  padding: 2px 4px 2px 4px;
  border: 1px solid black;
}
#login_background:hover{
  background: rgba(114,98,65);
  color: rgba(73,60,56);
  padding: 2px 4px 2px 4px;
  border: 1px solid black;
}
</style>